import { Drawer } from '@mui/material';
import React from 'react';

interface Props {
    container?: (() => HTMLElement)
    width: string,
    children: React.ReactElement[];
}

const SideBar = (props: Props) => {
    return (
        <Drawer 
            container={props.container}
            open={true}
            variant="permanent"
            PaperProps={{
                sx: {
                    width: props.width
                }
            }}
            >
            {props.children}
        </Drawer>
    );
}

export default SideBar;