import React, { useEffect, useRef, useState } from 'react';
import './App.css';
import { Alert, Avatar, Box, List, ListItem, ListItemAvatar, ListItemText, ListSubheader, Snackbar } from '@mui/material';
import Webcam from 'react-webcam';
import Marquee from 'react-fast-marquee';
import { useCollection, useDocumentData } from 'react-firebase-hooks/firestore';
import { collection, doc, getFirestore, limit, orderBy, query, where } from 'firebase/firestore';
import SideBar from './SideBar';

interface Booking {
  titleKp: string;
  titleJp: string;
  userDisplayName: string;
}

const videoConstraints: MediaTrackConstraints = {
  facingMode: 'user',
  aspectRatio: 4.0 / 3.0,
};

const styles: Record<string, React.CSSProperties> = {
  drawer: {
    width: '800px'
  },
  marquee: {
    width: '100%',
    height: '100%',
    backgroundColor: 'black'
  },
  marqueeContainer: {
    backgroundColor: 'black',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    gap: '5px',
    padding: '5px',
    borderRadius: '5px'
  },
  marqueeText: {
    width: '100%',
    fontSize: 'clamp(2rem, 5vw, 6rem)',
    fontWeight: 'bold',
    fontFamily: 'urigul',
    letterSpacing: '0.03em',
    background: 'radial-gradient(circle at center, #ffff38, .08rem, transparent 0)',
    backgroundSize: '.3rem .3rem',
    WebkitBackgroundClip: 'text',
    backgroundClip: 'text',
    color: 'transparent'
  }
}

const TitleMarquee = (props: { text: string, textSize: string }) => (
  <Marquee style={{ ...styles.marquee }} gradient={false}>
    <div style={{ ...styles.marqueeText, fontSize: props.textSize }}>{'　　　' + props.text}</div>
  </Marquee>
)

const drawerWidth = '400px';

const marqueeHeight = 120;

function App() {
  const [presentationSize, setPresentationSize] = useState([0, 0]);
  const presentationRef = useRef<HTMLDivElement>(null); 
  useEffect(() => {
    if (presentationRef.current) {
      const width = presentationRef.current.clientWidth;
      const height = presentationRef.current.clientHeight - 4;
      setPresentationSize([width, height]);
    }
  }, [presentationRef])

  const [videoBoxSize, setVideoBoxSize] = useState([0, 0]);
  const videoBoxRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (videoBoxRef.current) {
      const width = videoBoxRef.current.clientWidth;
      const height = videoBoxRef.current.clientHeight;
      setVideoBoxSize([width, height]);
    }
  }, [videoBoxRef])

  const firestore = getFirestore()
  const [settings, _loadingSettings, errorSettings, _snapshotSettings] = useDocumentData(
    doc(firestore, 'settings', 'event')
  );

  const [performedSnapshot, _loadingPerformed, errorPerformed] = useCollection(
    settings &&
    query(
      collection(firestore, `events/${settings.eventId}/bookings`),
      where('performedAt', '!=', null ), orderBy('performedAt', 'desc'), limit(1)),
  );
  const performingData = performedSnapshot?.docs?.[0]?.data();

  const [bookingSnapshot, _loadingBooking, errorBooking] = useCollection(
    settings &&
    query(
      collection(firestore, `events/${settings.eventId}/bookings`),
      where('createdAt', '!=', null ),
      where('cancelledAt', '==', null),
      where('performedAt', '==', null),
      orderBy('createdAt', 'asc'),
    ),
  )

  const shouldShowError = errorBooking || errorPerformed;
 
  const bookingData = bookingSnapshot?.docs?.map(it => it.data() as Booking) || [];
  if( bookingData.length === 0) {
//    throw new Error("Invalid length.");
  }

  const container = () => window.document.body;
  
  return (
    <Box sx={{ height: '100vh' }}>
      <Box component="nav" style={{width: '100%'}}>
        <SideBar container={container} width={drawerWidth}>
          <Box sx={{ width: '100%', height: '50%', backgroundColor: 'black' }}>
            <List>
              <ListSubheader 
                key="subheader"
                component="h3"
                sx={{ 
                  fontSize: '1.5em',
                  fontWeight: 'bold',
                  backgroundColor: 'transparent',
                  color: 'white'
                }}
              >
                順番待ち
              </ListSubheader>
              {
                bookingData.slice(0, 2).map((item, index) => (
                  <ListItem key={`waiting_list_${item.titleKp}_${item.titleJp}_${item.userDisplayName}`}>
                    <ListItemAvatar>
                      <Avatar sx={{ backgroundColor: '#333333'}}>
                        {index + 1}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      sx={{ color: 'white' }}
                      primary={
                        <React.Fragment>
                          <span style={{ fontFamily: 'urigul', fontSize: '3rem' }}>{item.titleKp}</span><br />
                          <span style={{ fontSize: '3rem' }}>{item.titleJp}</span><br />
                          <span style={{ fontSize: '3rem' }}>{item.userDisplayName}</span>
                        </React.Fragment>
                      }
                      secondary={item.userDisplayName}
                    />
                  </ListItem>
                ))
              }
            </List>
          </Box>
          <Box sx={{ width: '100%', height: '50%', backgroundColor: '#9b9b9b' }} ref={presentationRef}>
          <iframe
              sandbox="allow-scripts allow-same-origin"
              title='実行委員会からのお知らせ'
              src="https://docs.google.com/presentation/d/e/2PACX-1vRkYSSAzV7QuyiwtKqnGDHv0fsGsAirwITOp6WCCnenw3N5_M6uW_z-0t2gRSj1TZpw0lJbxptQACyl/embed?start=false&loop=false&delayms=3000"
              width={400}
              height={presentationSize[1]}
              style={{
                borderWidth: 0
              }}
            />
          </Box>
        </SideBar>
      </Box>
      <Box component="main" style={{
        display: 'flex',
        flexDirection: 'column',
        paddingLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth})`,
        height: '100%'
        }}>
        <Box sx={{ height: marqueeHeight, backgroundColor: 'black', width: '100%', overflow: 'hidden' }}>
          <TitleMarquee text={performingData?.titleKp || ''} textSize="64px"/>
        </Box>
        <Box style={{ flexGrow: 1, backgroundColor: 'black' }} ref={videoBoxRef}>
          <Webcam
            audio={false}
            width={videoBoxSize[0]}
            height={videoBoxSize[1]}
            screenshotFormat="image/jpeg"
            videoConstraints={videoConstraints}
          />
        </Box>
      </Box>
      <Snackbar open={Boolean(shouldShowError)} autoHideDuration={6000}>
        <Alert severity="error" sx={{ width: '100%' }}>
          {errorSettings?.message || ''}
          {errorPerformed?.message || ''}
          {errorBooking?.message || ''}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default App;
