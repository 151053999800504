import { FirebaseOptions } from "firebase/app";

interface AppOptionType {
    production: boolean;
    firebase: FirebaseOptions;
}

/**
 * Firebase 環境別設定を取得します
 * @returns Firebase Options
 */
function environment(): AppOptionType {
  if (process.env.REACT_APP_FIREBASE_ENV === 'staging') {
    return {
      production: false,
      firebase: {
        apiKey: 'AIzaSyBVRk4SUx3Ef9FZS5of2vmxhChKVdbFz3k',
        authDomain: 'dprk-karaoke-staging-apps.firebaseapp.com',
        databaseURL: 'https://dprk-karaoke-staging.firebaseio.com',
        projectId: 'dprk-karaoke-staging',
        storageBucket: 'dprk-karaoke-staging.appspot.com',
        messagingSenderId: '145421873466'
      },
    };
  } else if (process.env.REACT_APP_FIREBASE_ENV === 'production') {
    return {
      production: true,
      firebase: {
        apiKey: 'AIzaSyBL4pJO_pUGOs7Ut2UuTF3Q-U6YXTN4ZYo',
        authDomain: 'booking.dprk-karaoke.club',
        databaseURL: 'https://dprk-karaoke3.firebaseio.com',
        projectId: 'dprk-karaoke3',
        storageBucket: 'dprk-karaoke3.appspot.com',
        messagingSenderId: '93931761765',
        appId: '1:93931761765:web:688ba849bb66097b06968c',
        measurementId: 'G-YZ9SET5EL5'
      },
    };
  } else {
    throw Error(`REACT_APP_FIREBASE_ENV not set: env=${process.env.REACT_APP_FIREBASE_ENV}`);
  }
}

export default environment();